import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Select from "./Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const status = [
  {
    id: 1,
    name: "Success",
  },
  {
    id: 2,
    name: "Pending",
  },
  {
    id: 3,
    name: "Failed",
  },
];

const reason = [
  {
    id: 1,
    name: "Duplicate",
  },
  {
    id: 2,
    name: "Fraudulent",
  },
  {
    id: 3,
    name: "Gift Card Cancelled",
  },
  {
    id: 4,
    name: "Gift Not Acceptable",
  },
  {
    id: 5,
    name: "Gift Not Received",
  },
  {
    id: 6,
    name: "Unrecognized Giftcard",
  },
  {
    id: 7,
    name: "Giftcard Not Processed",
  },
]

const Dropdown = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [open, setOpen] = useState(true);
  const [filter, setFilter] = useState({
    date: false,
    reason: false,
    status:false
  })
  const [selected, setSelected] = useState({
    reason: reason[0],
    status:status[0]
  })

  

  return (
    <div>
      <Menu
        as="div"
        open={open}
        className="relative inline-block text-left mx-4"
      >
        <div>
          <Menu.Button
            open={open}
            onClick={() => {
              setOpen(!open);
            }}
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clip-rule="evenodd"
              />
            </svg>
            <p className="ml-2">Filter</p>
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="flex justify-between p-4 bg-slate-200">
              <button className="px-2 border text-xs rounded-sm shadow-md" onClick={() => setOpen(false)}>
                Clear
              </button>

              <div>Filter</div>

              <button className="px-2 bg-blue-500 text-white text-xs rounded-sm shadow-md">
                Done
              </button>
            </div>
            <div className="py-1">
              <Menu.Item
                onClick={(e) => {
                  e.preventDefault()
                  setOpen(!open);
                }}
              >
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="flex items-center h-5">

                      <input
                        onClick={(e) => {
                          setFilter({...filter, date:!filter.date})}}
                        // value={true}
                        id="date"
                        name="date"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />

                      <div>
                        <p className="ml-2">Date</p>
                      </div>

                    </div>
                    {filter.date && 
                    <div className="flex pt-3">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="w-20 h-8 pl-1 text-sm"
                        placeholderText="From"
                      />
                      {/* <p>to</p> */}
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="w-20 h-8 pl-1 text-sm ml-2"
                        placeholderText="To"
                      />
                    </div>
                    }
                  </a>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              <Menu.Item 
                onClick={(e) => {
                e.preventDefault()
                setOpen(!open);
                }}
              >
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="flex items-center h-5">
                      <input
                        onClick={(e) => {
                          setFilter({...filter, reason:!filter.reason})}}
                        id="reason"
                        name="reason"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />

                      <p className="ml-2">Reason</p>
                    </div>
                    {filter.reason &&
                    <div className="pt-3">
                      <Select why="Select Reason" type="reason" selected={selected.reason} setSelected={(e) => setSelected(e)} list={reason} />
                    </div>
                    }
                  </a>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              <Menu.Item                
                onClick={(e) => {
                e.preventDefault()
                setOpen(!open);
                }}
              >
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    <div className="flex items-center h-5">
                      <input
                        onClick={(e) => {
                          setFilter({...filter, status:!filter.status})}}
                        id="status"
                        name="status"
                        type="checkbox"
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />

                      <p className="ml-2">Status</p>
                    </div>
                    {filter.status &&
                    <div className="pt-3">
                      <Select why="Select Status" type="status" selected={selected.status} setSelected={(e) => setSelected(e)} list={status} />
                    </div>
                    }
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default Dropdown;
