import React, {useState} from 'react'
import PaymentDetails from './PaymentDetails'
import goBack from '../asset/arrow-left.svg'
import Transactions from './Transactions'
import Back from './Back'

const TransactionView = () => {
    const [back, setBack] = useState(true)

    const menuItems = [
        {
            title: "Merchant Name",
            text: "Adjoa Atta"
        },
        {
            title: "Amount",
            text: "2500"
        },
        {
            title: "Status",
            text: "Pending"
        },
        {
            title: "Date Time",
            text: "22/06/2022"
        },
        {
            title: "Reference",
            text: "Prime"
        },
        {
            title: "Customer Phone Number",
            text: "0207935904"
        },
        {
            title: "Customer Name",
            text: "Ama Ghana"
        }
    ]
    

  return (
    <div>
        <div className='max-w-7xl pb-10 bg-white'>
            <div className='flex justify-between border-b-2'>
                <div className='mb-2'>
                    <p className='ml-3 p-2'> Adwoa Atta </p>

                    <div className='flex'>
                        <p className='ml-4 text-3xl'> GHS 2500 </p>
                        <p className='bg-yellow-500 ml-2 rounded-lg py-1 px-1 text-sm m-1'> Pending </p>
                    </div>
                </div>
                <div className='flex mt-4 h-10'>
                    <button className='px-5 py-2 flex border text-sm mr-4 rounded-sm shadow-md'>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                        </svg>
                        Complete
                    </button>
                    <button className='px-5 py-2 flex border text-sm mr-4 rounded-sm shadow-md'>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                    </svg>
                        Reverse
                    </button>
                    <button className='px-5 py-2 flex border text-sm mr-4 rounded-sm shadow-md'>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                    </svg>
                        Fail
                    </button>
                </div>
            </div>
            <section>
                <div className='flex'>
                    {menuItems.map((items, i) => (
                            <div key={i} className='px-3 mt-3 border-r-2'>
                                <p>{items.title}</p>
                                <p className='mt-2'>{items.text} </p>
                            </div>
                        )
                    )}
                
                </div>
            </section>
            <PaymentDetails/>

            <div className='flex justify-center mt-3'>
                <button className='bg-primary py-2 px-5 text-white shadow-lg flex'>
                    Export
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>
            </div>

        </div>

    </div>   
  )
}

export default TransactionView