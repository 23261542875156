import axios from "axios";
import Cookies from 'js-cookie'

const token = Cookies.get('token')

export const urls = {
    development: 'https://support-api.primeegiftcard.com/ps/v1',
    production: 'https://support-api.primeegiftcard.com'
}


const api = axios.create({
    baseURL: urls[process.env.NODE_ENV],
    headers: {
        'Content-Type': 'application/json',
        'da-secret': 'pCweb'
    }
})

if (token) {
    api.defaults.headers.Authorization = `${token}`
}

export default api