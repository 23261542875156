import React, { useState, useEffect } from 'react'
import TableComponent from '../Component/TableComponent'
import Layout from '../Layout/Layout'
import api from '../Axios/config'
import emptyTable from '../asset/empty_table.jpg'
import Modal from '../Component/Modal'
import CreateTickets from '../Component/Tickets/CreateTickets'

//Redux
import { useDispatch, useSelector } from 'react-redux'
import { getTickets } from '../Redux/Tickets/action'
import TicketInfo from '../Component/TicketInfo'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import Dropdown from '../Component/Dropdown'

const Tickets = () => {
  const dispatch = useDispatch()
  const { tickets } = useSelector(state => state.tickets)

  const [data, setData] = useState([])
  const [showModal, setShowModal] = useState(false)

  const fetchTickets = () => {
    dispatch(getTickets())
    .then((response)=>{
      console.log('Ticket Response Data',response.data)
      // let ticket = tickets?.map((el) => {
      //   let tickets = {
      //     title: "System Error",
      //     description: el.title,
      //     type: el.body,
      //     dateCreated: "02-05-22",
      //     status: "Pending",
      //     priority: "High",
      //     assingedGroup: "Urgent"
      //   }
      //   return tickets
      // })
      // setData(ticket)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    fetchTickets()  
  },[])

  

  const tableHeading = {
   title: "Title",
   description: "Description",
   type: "Type",
   dateCreated: "Date created",
   priority: "Priority",
   assingedGroup: "Assigned group"
  }



  return (
    <Layout title="Tickets">
      <div>
        {/* <TableComponent
        tableHeading={tableHeading}
        list={data}
        placeholder="Search for Tickets"
        /> */}

        <div className='flex justify-end'>
          <div>
            <button 
              className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500" 
              onClick={() => setShowModal(!showModal)}
            > 
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
              </svg>
             Create 
            </button >
          </div>
          <Dropdown/>
        </div>

        {tickets.length > 0 ?
        tickets?.map((el,index)=> (
        <TicketInfo
        ticketNumber= {el.code}
        priority={el.priority_type.name}
        date={new Date(el.created_at)?.getTime() }
        title={el.title}
        info={el.description}
        name={el.created_by.forname} 
        />
        ))
        :
        <div className="w-full p-3 text-xl text-center mt-4 bg-white">
          <div className=' max-w-7xl'>
          <img src={emptyTable} alt="empty table" className="w-76 mx-auto mt-5 mb-10" />
          There are no Tickets to show 
          </div>
        </div>
      }

      {showModal && 
        <Modal setShowModal={setShowModal}>
          <CreateTickets setShowModal={setShowModal}/>
        </Modal>
      }
      </div>
    </Layout>
  )
}

export default Tickets