/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import ModalItems from "./ModalItems";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Modal({children,showModal, setShowModal}) {
  // const [showModal, setshowModal] = useState(true);
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  let [categories] = useState({
    Recent: [
      {
        id: 1,
        title: <ModalItems/>,
        // date: "5h ago",
        // commentCount: 5,
        // shareCount: 2,
      },
      // {
      //   id: 2,
      //   title: "So you've bought coffee... now what?",
      //   date: "2h ago",
      //   commentCount: 3,
      //   shareCount: 2,
      // },
    ],
    // Popular: [
    //   {
    //     id: 1,
    //     title: "Is tech making coffee better or worse?",
    //     date: "Jan 7",
    //     commentCount: 29,
    //     shareCount: 16,
    //   },
    //   {
    //     id: 2,
    //     title: "The most innovative things happening in coffee",
    //     date: "Mar 19",
    //     commentCount: 24,
    //     shareCount: 12,
    //   },
    // ],
    // Trending: [
    //   {
    //     id: 1,
    //     title: "Ask Me Anything: 10 answers to your questions about coffee",
    //     date: "2d ago",
    //     commentCount: 9,
    //     shareCount: 5,
    //   },
    //   {
    //     id: 2,
    //     title: "The worst advice we've ever heard about coffee",
    //     date: "4d ago",
    //     commentCount: 1,
    //     shareCount: 2,
    //   },
    // ],
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setShowModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative p-5 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
