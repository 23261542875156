import * as types from "./types"


const initState = {
    date: {
        from: "",
        to: ""
    },
    reason: "",
    status: "",
    loading: false,
}

export const general = (state = initState, action) => {
    switch(action.type) {
        case types.FILTER:
            return {
                ...state,
                ...action.payload
            }
        
        default:
        return state;
    }

}