import api from "../../Axios/config";
import * as types from './types'

export const getTickets = () => async (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/dashboards/tickets')
        .then((response) => {
            dispatch({
                type: types.TICKETS,
                payload: response.data.data
            })

            resolve(response)
        })
        .catch((error) => {
            reject(error)
        })
    })
}

