import React, { Children, useState } from "react";
import TableFilter from "./TableFilter";

//Image
import emptyTable from '../asset/empty_table.jpg'
import Modal from "./Modal";


//Modal

const TableComponent = ({
  tableHeading = {},
  list = [],
  placeholder="",
  loading = false,
  modalOnClick = true,
  handlePageRoute = () => {}
}) => {
  const [showModal, setShowModal] = useState(false)


  return (
    <div class="relative bg-white overflow-x-auto shadow-md sm:rounded-lg">
      <TableFilter placeholder={placeholder}/>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {Object.keys(tableHeading).map((item, index) => (
              <th scope="col" className="px-6 py-3" key={index}>
                {tableHeading[item]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          { list &&
            list?.length > 0 &&
            list.map((item, index) => (
              <tr 
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" 
                key={index}
                onClick = {() => {
                  modalOnClick ? 
                  setShowModal(!showModal): handlePageRoute(item)
                }}
              >
                {Object.keys(item).map((data, i) => (  
                  <td className="px-6 py-4 text-black" key={i}>
                    {(item[data])}
                  </td>
                ))}
              </tr>
            ))

          }
        </tbody>
      </table>

      {loading ? 
      <div className="h-full w-full absolute inset-0 bg-primary bg-opacity-25 rounded-b-lg transition-all ease-in duration-700 flex justify-center items-center">
        <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg> 
      </div> 
      :
      list && list?.length === 0 &&
       <div className="w-full">
        <img src={emptyTable} alt="empty table" className="mx-auto mt-3" />
        <div className="text-center mt-2 mb-5">There's No Data Available</div>
       </div>
      }

      {showModal &&
      <Modal/>
      }
    </div>
  );
};

export default TableComponent;
