import React, { useState } from "react";
import TableComponent from "../Component/TableComponent";
import Layout from "../Layout/Layout";
import TransactionView from "./TransactionView";
import Back from "./Back";

const Transactions = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [back, setBack] = useState(true);

  const tableHeading = {
    merchantName: "Merchant Name",
    amount: "Amount",
    status: "Status",
    date: "Date Time",
    reference: "Reference",
    phoneNo: "Customer Phone Number",
    customerName: "Customer Name",
  };

  const list = [
    // {
    //   merchantName: "Adjoa Atta",
    //   amount: "2500",
    //   status: "Pending",
    //   date: "22/06/2022",
    //   reference: "Prime",
    //   phoneNo: "0207935904",
    //   customerName: "Ama Ghana",
    // },
    // {
    //   merchantName: "Adjoa Atta",
    //   amount: "2500",
    //   status: "Pending",
    //   date: "22/06/2022",
    //   reference: "Prime",
    //   phoneNo: "0207935904",
    //   customerName: "Ama Ghana",
    // },
    // {
    //   merchantName: "Adjoa Atta",
    //   amount: "2500",
    //   status: "Pending",
    //   date: "22/06/2022",
    //   reference: "Prime",
    //   phoneNo: "0207935904",
    //   customerName: "Ama Ghana",
    // },
    // {
    //   merchantName: "Adjoa Atta",
    //   amount: "2500",
    //   status: "Pending",
    //   date: "22/06/2022",
    //   reference: "Prime",
    //   phoneNo: "0207935904",
    //   customerName: "Ama Ghana",
    // },
    // {
    //   merchantName: "Adjoa Atta",
    //   amount: "2500",
    //   status: "Pending",
    //   date: "22/06/2022",
    //   reference: "Prime",
    //   phoneNo: "0207935904",
    //   customerName: "Ama Ghana",
    // },
  ];

  const handlePageRoute = (item) => {
    setShowDetails(true);
  };

  const SetBack = () => {
    setBack(false);
  };

  return (
    <Layout title="Transactions">
      <div>
        <div className="mt-3">
          {showDetails ? (
            <>
              <Back setBack={() => setShowDetails(!showDetails)}/>
              <TransactionView />
            </>
          ) : (
            <TableComponent
              tableHeading={tableHeading}
              list={list}
              placeholder="Search by Number, Location Name, Branch Code"
              modalOnClick={false}
              handlePageRoute={handlePageRoute}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Transactions;
