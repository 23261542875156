import React from 'react'
import More from '../asset/more.svg'

const TicketInfo = ({ticketNumber = "", date, priority, title = "", info = "", name = ""}) => {
  return (
    <div className='w-full'>
        <div className="bg-white m-3 p-3 rounded-lg shadow-lg hover:shadow-2xl hover:bg-gray-200">
                <div className="flex items-start justify-between">
                    <div className="w-full flex items-center text-base"> 
                        <p className="text-blue-600 pr-2"> Ticket #{ticketNumber}</p>

                        {/* <div className="w-1 h-1 bg-blue-500 rounded-full"></div>

                        <p className="text-blue-600 flex items-center justify-center capitalize pl-2">
                            <span className="">{district}</span>
                        </p> */}
                    </div>
                    <div className="flex text-base pt-2">
                        <p className="text-gray-300 font-normal mr-5">{date}</p>
                        <img src={More} className="cursor-pointer mr-2" alt="" />
                    </div>
                </div>

                <div className="pt-3">
                    <p className="text-lg font-medium">{title}</p>
                    <p className="text-gray-500 font-medium mt-2 mb-3">{info}</p>

                    <p className='mb-3'>Priority <span className='bg-primary px-2 py-1 text-white rounded-xl'>{priority}</span> </p>

                    <div className='flex justify-between'>
                        <div className='flex'>
                            <img
                                className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt=""
                            />
                            <p className="text-gray-900 ml-2">{name}</p>
                        </div>

                        <div className='flex'>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                </svg>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                                <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default TicketInfo