import React from 'react'
import TableComponent from '../Component/TableComponent'
import Layout from '../Layout/Layout'

const Disbursements = () => {
  return (
    <Layout title="Disbursements">
      <div>
        <TableComponent
        placeholder='Search for Disbursements'
        />
      </div>
    </Layout>
  )
}

export default Disbursements