import React from 'react'
import Widget from '../Component/Widget'
import Layout from '../Layout/Layout'

const Index = () => {
  return (
    <Layout title="Dashboard">
      <div className='w-full'>
        <Widget/>
      </div>
    </Layout>
  )
}

export default Index