import React from 'react'

const PaymentDetails = () => {
    const paymentData = [
        {
            name: "Name",
            info: "Adjoa Atta"
        },
        {
            name: "Amount",
            info: "2500"
        },
        {
            name: "Status",
            info: "Pending"
        },
        {
            name: "Date",
            info: "22/06/2022"
        },
    ]

  return (
    <div className='mt-24 ml-3'>
        <div>
            <p className='font-bold text-xl border-b-2'>Payment Details</p>
            
            <section className='mt-3'>
                {paymentData.map((item, i) => (
                    <div key={i} className='flex mb-2'>
                        <p className=' mr-12'> {item.name} </p>
                        <p> {item.info} </p>
                    </div>
                ))}
            </section>
        </div>
    </div>
  )
}

export default PaymentDetails