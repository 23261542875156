import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Pages
import Login from './Component/Login';
import Index from './Pages/Index';
import Transactions from './Pages/Transactions';
import Disbursements from './Pages/Disbursements';
import Tickets from './Pages/Tickets';


function App() {
  return (
    <div className="App">
      {/* <Login/>
      <Layout/> */}
      {/* <Navbar/> */}
      <Router>
        <Routes>
          <Route exact path="/"  element={<Index/>}/>
          <Route path="/login"  element={<Login/>}/>
          <Route path="/transactions"  element={<Transactions/>}/>
          <Route path="/tickets"  element={<Tickets/>}/>
          <Route path="/disbursements"  element={<Disbursements/>}/>
        </Routes>

      </Router>
      <ToastContainer/>
    </div>
  );
}

export default App;
