import { data } from "autoprefixer";
import * as types from "./types"


const initState = {
    tickets: []
}

export const tickets = (state = initState, action) => {
    switch(action.type) {
        case types.TICKETS:
            return {
                ...state,
                ...action.payload
            }
        
        default:
        return state;
    }

}