import React from 'react'

const ModalItems = () => {
  return (
    <div>
        
        <input className='w-full py-3 border border-black rounded-lg text-center mb-5 ' placeholder='Ticket Type' />

        <input className='w-full py-3 border border-black rounded-lg text-center mb-5' placeholder='Ticket Title' />

        
        <div className='flex'>
            {/* <label>Priority Type</label> */}
            <select value='' onChange='' className='w-full py-3 border border-black rounded-lg text-center mb-5 mr-2'>
                <option>Priority Type</option>
                <option value='high'>High</option>
                <option value='medium'>Medium</option>
                <option value='low'>Low</option>
            </select>
            <select value='' onChange='' className='w-full py-3 border border-black rounded-lg text-center mb-5'>
                <option>Ticket Type</option>
                <option>Change Request</option>
                <option>Review Ticket</option>
                <option>Gift Ticket</option>
            </select>
        </div>
        

        <input className='w-full py-3 border border-black rounded-lg text-center' placeholder='Ticket Title' />
        
        <textarea value='' onChange='' className='w-full mt-5 border border-black' placeholder='Ticket Details' rows="4" />
                
    </div>
  )
}

export default ModalItems