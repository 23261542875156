import { combineReducers } from "redux";
import { general } from "../Redux/General/reducer";
import { tickets } from "../Redux/Tickets/reducer";
import { transactions } from "../Redux/Transactions/reducer";

const rootReducer = combineReducers({
    general:general,
    tickets: tickets,
    transactions: transactions,
})


export default rootReducer;