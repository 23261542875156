import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from 'js-cookie'
import prime from "../asset/prime-logo.jpeg";
import api from "../Axios/config";

const Login = () => {
  const navigate = useNavigate();
    const [loading, setloading] = useState(false)
    const [data, setData] = useState({
      forname: "",
      password: ""
    })

    const handleOnSubmit = async (e) =>{
      e.preventDefault()
      setloading(true)

      api.post('/users/admin/login', data)
      .then((response) => {
        setloading(false)
        console.log('Cookie Response', response.data.data.token)
        Cookies.set('token', response.data.data.token) 
        toast.success('Login Successful', 
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          }
        )
        navigate("/")
      })
      .catch((err) => {
        setloading(false)
        toast.error(err.response.data.error_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        console.log(err)
      })
      
    }

    const {forname, password} = data


  return (
    <div className="h-full w-full text-center">
      <div className="w-full h-full bg-gray-200">
        <div className="w-full justify-center items-center py-44">
          <div className="flex items-center justify-center mb-2">
            <img className="w-28" src={prime} alt="" />
          </div>
          <div className=" w-1/4 mx-auto bg-white rounded-lg shadow-lg justify-center items-center p-4">
            <section className="p-4 mt-4 rounded-lg">
              <p className="text-2xl font-bold">Login</p>
            </section>

            <section>
              <form className="flex flex-col mt-2" onSubmit={handleOnSubmit}>
                <div className="mb-6 pt-3">
                  <input
                    name="forname"
                    className="rounded border border-gray-200 px-2 py-2 w-full"
                    type="text"
                    placeholder="Username"
                    onChange={(e) => setData({...data,forname:e.target.value})}
                    required
                  />
                </div>

                <div>
                  <input
                    name="password"
                    className="rounded border border-gray-200 px-2 py-2 w-full"
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setData({...data,password:e.target.value})}
                    required
                  />
                </div>

                <div className="mt-5 mb-5">
                  <button  className="w-full flex bg-blue-500 rounded-lg shadow-lg px-3 py-2 text-white font-bold" type="submit">
                    {loading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    <p className="mx-auto">Sign In</p>
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
