import React, { Children } from 'react'
import goBack from '../asset/arrow-left.svg'

const Back = ({setBack = () => {} }) => {
  return (
    <section>
        <div
        onClick = {() => setBack(false)}
        >
            <img src={goBack} alt='' className='w-9 mb-5 cursor-pointer' />
        </div>
       
    </section>
  )
}

export default Back