import React, {useState, useRef} from "react";
import { Tab } from "@headlessui/react";
import ModalItems from "../ModalItems";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const CreateTickets = ({setShowModal}) => {
    const [open, setOpen] = useState(true);

    const cancelButtonRef = useRef(null);

    let [categories] = useState({
        Recent: [
          {
            id: 1,
            title: <ModalItems/>,
            // date: "5h ago",
            // commentCount: 5,
            // shareCount: 2,
          },
        ]
    })


  return (
    <div>
      <div className="">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            {Object.keys(categories).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                    "ring-opacity-60 ring-offset-2 ring-offset-primary focus:outline-none ",
                    selected
                      ? "bg-primary text-white shadow"
                      : "text-primary bg-white"
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {Object.values(categories).map((posts, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames(
                  "rounded-xl bg-white p-3",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                )}
              >
                <ul>
                  {posts.map((post) => (
                    <li
                      key={post.id}
                      className="relative rounded-md p-3 hover:bg-gray-100"
                    >
                      <h3 className="text-sm font-medium leading-5">
                        {post.title}
                      </h3>

                      {/* <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                  <li>{post.date}</li>
                                  <li>&middot;</li>
                                  <li>{post.commentCount} comments</li>
                                  <li>&middot;</li>
                                  <li>{post.shareCount} shares</li>
                                </ul> */}

                      {/* <a
                                  href="#"
                                  className={classNames(
                                    "absolute inset-0 rounded-md",
                                    "ring-blue-400 focus:z-10 focus:outline-none focus:ring-2"
                                  )}
                                /> */}
                    </li>
                  ))}
                </ul>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="bg-gray-50 px-4 py-3 mt-5 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setOpen(false)}
        >
          Update
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setShowModal(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CreateTickets;
